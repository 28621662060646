import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Pagination from '../components/Pagination';
import Button from '../components/Button';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import Img from 'gatsby-image';
import SignUpForm from '../components/SignUpForm';
import PreHeader from '../components/PreHeader';

const BlogHero = styled.section`
	padding-top: 1rem;
	text-align: center;
	background-color: ${cwTheme.white};
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 1rem 0 2rem 0;
	}
`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const BlogHeader = styled.h1`
	margin-top: 0;
	font-family: ${cwTheme.serif};
	margin-bottom: 1rem;
`;

const BlogMain = styled.section``;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 .5rem;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: row;
		padding: 0 1rem;
	}
`;
const IndexContainer = styled.div`
	flex: 4;
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	display: grid;

	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-gap: 1rem;

	@media (min-width: ${cwTheme.smallBreakPoint}) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin-bottom: 0;
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const PostCard = styled.div`
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	border: 1px solid #e4e4e4;
	border-radius: ${cwTheme.borderRadius};
`;

const BlogLinkWrapper = styled.div`
	min-height: 100%;
	background: rgba(255, 255, 255, 1);
	text-decoration: none;
	color: #444;
	display: flex;
	flex-direction: column;

	article {
		padding-bottom: .25rem;
		display: flex;
		flex-direction: column;
		flex: 1;
	}
`;

const PostTitle = styled(Link)`
	flex: 1;
	padding: 10px;
	line-height: 1.18421053em;
	display: block;
	font-weight: 600;
	color: inherit;
	text-decoration: none;
	width: 100%;
	display: block;
	font-size: 1.25em;

	&:hover {
		color: ${cwTheme.blackHover};
	}
`;

const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0 10px 0 10px;
	a {
		background-color: ${cwTheme.secondaryBlue};
		padding: 2px 8px;
		border-radius: 16px;
		text-decoration: none;
		color: white;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
	}

	a:hover {
		background-color: ${cwTheme.lightBlue};
	}
`;

const CategoriesContainer = styled.div`
	flex: 1;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	h6 {
		margin: 0;
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin-left: 2.5rem;
		margin-botton: 0;
		display: block;
	}
`;
const CategoriesList = styled.ul`
	padding-left: 5px;
	text-decoration: none;
	list-style: none;
	margin-top: 0;
	line-height: 2;

	a {
		text-decoration: none;
		color: ${cwTheme.blackHover};
		font-size: 1rem;
	}

	a:hover {
		color: rgba(61, 61, 61, .5);
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	li {
		padding: .2rem .5rem;
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		display: block;
		text-align: left;
		li {
			padding: 0;
		}
	}
`;

const CategoryHome = styled.li`
	padding: .2rem .5rem;
	a {
		font-weight: 700;
	}
`;

function isEmpty(array) {
	if (array.length < 1) {
		return true;
	} else {
		return false;
	}
}

const TagHome = ({ data, pageContext }) => {
	console.log(data);
	console.log(pageContext);

	const posts = data.allContentfulPost.edges;

	const allTags = data.allContentfulTag.edges;

	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title="Blog" />

				<BlogHero>
					<Container>
						<PreHeader>TOPIC:</PreHeader>
						<BlogHeader>{data.contentfulTag.title}</BlogHeader>
					</Container>
				</BlogHero>

				<BlogMain>
					<InnerWrapper>
						<IndexContainer>
							{posts.map(({ node: post }, i) => (
								<PostCard key={post.id}>
									<BlogLinkWrapper>
										<Link to={'blog/' + post.slug}>
											<Img backgroundColor={cwTheme.lightBlue} sizes={post.mainImage.fluid} />
										</Link>

										<article>
											<PostTitle to={'blog/' + post.slug}>{post.title}</PostTitle>
											<TagContainer>
												{isEmpty(post.tags) ? (
													''
												) : (
													post.tags.map((postTag) => (
														<Link
															key={'post' + postTag.id}
															to={'/blog/topic/' + postTag.slug}
														>
															{postTag.title}
														</Link>
													))
												)}
											</TagContainer>
										</article>
									</BlogLinkWrapper>
								</PostCard>
							))}
						</IndexContainer>

						<CategoriesContainer>
							<CategoriesList>
								<CategoryHome>
									<Link to={'/blog'} activeStyle={{ color: cwTheme.secondaryBlue }}>
										All Topics
									</Link>
								</CategoryHome>
								{allTags.map(({ node: tag }) => (
									<li key={'category' + tag.id}>
										<Link
											to={'/blog/topic/' + tag.slug}
											activeStyle={{ color: cwTheme.black }}
											partiallyActive={true}
										>
											{tag.title}
										</Link>
									</li>
								))}
							</CategoriesList>
						</CategoriesContainer>
					</InnerWrapper>
				</BlogMain>

				<Pagination context={pageContext} />

				<SignUpForm header={'Enjoyed these posts? Receive the next one in your inbox!'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($skip: Int!, $limit: Int!, $slug: String!) {
		allContentfulPost(
			sort: { fields: [publishDate], order: DESC }
			limit: $limit
			skip: $skip
			filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
		) {
			totalCount
			edges {
				node {
					title
					id
					slug
					publishDate(formatString: "MMMM DD, YYYY")
					tags {
						title
						slug
						id
					}
					mainImage {
						fluid(maxWidth: 800, maxHeight: 500, quality: 100) {
							src
							...GatsbyContentfulFluid_noBase64
						}
					}
				}
			}
		}
		contentfulTag(slug: { eq: $slug }) {
			title
		}
		allContentfulTag(sort: { fields: title, order: ASC }) {
			edges {
				node {
					slug
					title
					id
				}
			}
		}
	}
`;

export default TagHome;
